(function ($) {

    // **** flexslider plugin *******

    var $window = $(window),
        flexslider = {
            vars: {}
        };

    function getScreenSize() {
        return (window.innerWidth < 768) ? 1 :
            (window.innerWidth < 1280 && window.innerWidth >= 768) ? 2 : 3;
    }

    $(window).on('load', function () {
        $('.flexslider').flexslider({
            slideshow: false,
            animation: "slide",
            animationLoop: true,
            itemWidth: 230,
            itemMargin: 30,
            minItems: getScreenSize(),
            maxItems: getScreenSize(),
            move: getScreenSize(),
            startAt: 0,
            start: function (slider) {
                flexslider = slider;
            }
        });
    });

    $window.resize(function () {
        var gridSize = getScreenSize();

        flexslider.vars.minItems = gridSize;
        flexslider.vars.maxItems = gridSize;
    });


    $(document).ready(function () {

        // ********** jQuery plugins ************ 

        $('body').scrollToAnchors();

        $('.glyphicons').glyphClone();

        $('.jumbotron a[title^=button], .passage a[title^=button], .usp-content a[title^=button], .footer a[title^=button]').mdLinkToButton();

        $('.btn').createTrackingClass();

        $('body').etrackerButtonEvents();

        $('.body-content').linkTap();

        $(window).scrollTopArrow();

        $('.navbar').menuScrollAnimations();

        $('.navbar').menuActive();

        $('body').fadePage();

        $('.bob-frame-show').bobframe();

    });

}(jQuery));