import * as Globals from './globals_reviga_ch.js';

(function($) {

    /**
     *
     * @returns {jQuery}
     */
    $.fn.bobframe = function() {

        // set bob constants
        let g = Globals;
        let event_namespace = '.bobFrame';
        let $frame = $(g.FRAME_SELECTOR);

        /**
         * Hide bob frame
         */
        let bobHide = function() {

            // Reestablish main page

            let bobFrameState = _.getJsonSessionStorage(g.STORAGE_FRAME_STATE);
            $(window).scrollTop(bobFrameState.scrollPosMain);
            $('body').removeClass('bob-frame-open');

            // Prepare bob-frame for hiding

            $frame.addClass('bob-fading-in');

            let scrollFrame = $(window).scrollTop();
            $frame.css('top', '-' + scrollFrame + 'px');

            // listener can be found in bobmsg2
            _.throwEvent(document, g.EVENT_FRAME_HIDE);

            bobFadeOut();
        };

        /**
         * remove the contains-... class that is set on the frame
         */
         let removeFrameStyleClass = function() {
            let matches = $frame.attr('class').match(/contains-\S*/g);
            $.each(matches, function(idx, match) {
                $frame.removeClass(match);
            });
        }

        /**
         * Fade out bob-frame
         */
        let bobFadeOut = function() {

            $frame.fadeOut(400, function() {

                $frame.css('top', '0');
                $('.bob-content', $frame).html('');
                $frame.removeClass('bob-fading-in');

                removeFrameStyleClass();

                _.setFocusToTriggerItem();

                _.mergeJsonSessionStorage(g.STORAGE_FRAME_STATE, {'frameOpen': false});

                _.throwEvent($frame, g.EVENT_FRAME_AFTER_HIDE);

            });

        };

        /**
         * Open bob frame
         * @param src
         * @param callerId
         */
        let bobShow = function(src, callerId) {


            // this state is used to allow the user to press the back button to close the bob-frame
            window.history.pushState('forward', null, './#bob-frame');

            // clean up messages, content, and data
            $('.bob-msg').hide();
            $('.bob-content', $frame).html('');

            // show new content
            let copy = setContent(src);
            showContent(copy);

            // init form methods
            let options = getFormOptions(src);

            if (_.has(options, 'formModule')) {
                $('form', $frame)[options.formModule](options);
            }

            // init newly created bobframe buttons
            // incase if you have a bobframe button inside another bobframe
            $('.bob-frame .bob-frame-show').bobframe();

            // set state of main page - extra method
            let bobFrameState = {
                'scrollPosMain': $(window).scrollTop(),
                'triggerItem': callerId,
                'frameOpen': true,
            };
            _.setJsonSessionStorage(g.STORAGE_FRAME_STATE, bobFrameState);

            // fade in form
            bobFadeIn();
        };

        /**
         * Fade in bob-frame
         */
        let bobFadeIn = function() {

            $frame.hide(0, function() {

                $frame.addClass('bob-fading-in');

                $frame.fadeIn(200, function() {
                    $(window).scrollTop(0);
                    $frame.removeClass('bob-fading-in');
                    $('body').addClass('bob-frame-open');

                    // When opening the form, the first input is focused.
                    // This will make the screen reader explain instantly whats going on in the form
                    let firstElementInForm = $frame.find('input').first();
                    $(firstElementInForm).focus();
                });

            });
        };

        /**
         * Set frame content
         * @param src
         */
        let setContent = function(src) {

            let suffix = '-framed';

            // copy content from template
            let copy = $(src).clone();
            let copyId = copy.attr('id');
            if (copyId) {
                copy.attr('id', copyId + suffix);

                // remove the classes that were set before
                removeFrameStyleClass();
                // add the new class to the frame
                // to enable us to have different styling based on which frame is curretntly open
                $frame.addClass('contains-' + copyId);
            }

            // modify copied ids to stay unique (inputs and labels)
            $(':input[id], button[id], a[id]', copy).each(function(idx, item) {
                if (item.id) {
                    item.id = item.id + suffix;
                }
            });
            $('[for]', copy).each(function(idx, item) {
                let old = item.getAttribute('for');
                item.setAttribute('for', old + suffix);
            });

            // IE bug: when cloning, placeholder text is inserted as value
            // Fix: if placeholder equals value, remove value
            $('textarea', copy).each(function(i, ta) {
                if ($(ta).attr('placeholder') === $(ta).val()) {
                    $(ta).val('');
                }
            }); // end IE bugfix

            return copy;
        };

        /**
         * Show content
         */
        let showContent = function(copy) {

            copy.hide();

            $('.bob-content', $frame).html(copy);

            copy.fadeIn();

        };

        /**
         * Get form options
         * Form options are configured in a json config file and stored in a js template
         */
        let getFormOptions = function(src) {

            let bobForms = document.bob.forms;

            let formOptions = {};
            _.forIn(bobForms, function(item) {

                let formId = '#' + item.prefix + item.idForm.substr(1);

                if (src === formId) {
                    formOptions = item;
                }
            });

            return formOptions;
        };

        /**
         * removes the hash that was created by the bob frame
         */
        let removeHash = function  () {
            if (window.location.href.indexOf("#bob-frame") > -1) {
                window.history.replaceState("", document.title, window.location.pathname + window.location.search);
            }
        }

        /**
         * --------------------------
         */

        // Initialise event handlers

        // First, reset all handlers
        $(window).off(event_namespace);
        $(document).off(event_namespace);
        $frame.off(event_namespace);

        /**
         * Open bob-frame when clicking a link that was initialised by the plugin
         * "e.target" is the clicked link element to open the frame
         * "src" is the id of the content source
         */
        this.off(event_namespace);
        this.on('click' + event_namespace, function(e) {
            let callerId = $(e.currentTarget).attr('id');
            let src = $(e.currentTarget).attr('href');

            if (src && src.substr(0, 1) === '#' && callerId) {
                e.preventDefault();
                bobShow(src, callerId);
            }
        });

        /**
         * Load a new bob-frame (e.g. from a form that is not in a frame)
         */

         $frame.on(g.EVENT_FRAME_OPEN + event_namespace, function(e) {
             
            // TODO: callerId find out what do do with it
            bobShow(e.bobOptions.src, 'callerId');
        });

        /**
         * Load the next content into bob-frame
         */
         $frame.on(g.EVENT_FRAME_CONTENT_NEXT + event_namespace, function(e) {

            // show new content
            let copy = setContent(e.bobOptions.src);
            showContent(copy);

            // init form module, bind form method
            let options = getFormOptions(e.bobOptions.src);

            if (_.has(options, 'formModule')) {
                $frame[options.formModule](options);
            }
            
            // set form data
            _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FRAME_CONTENT_CHANGED);
        });

        /**
         * Remove form data from session storage after bob-frame has closed
         */
         $frame.on(g.EVENT_FRAME_AFTER_HIDE + event_namespace, function() {
            _.removeSessionStorage(g.STORAGE_FORM_DATA);
        });

        /**
         * Close bob-frame after successfully submitting the form
         */
         $frame.on(g.EVENT_FORM_SUCCESS + event_namespace, function() {
            window.history.back();
        });

        /**
         * Close bob-frame if the browsers-back button is pressed!
         */
        $(window).on('popstate' + event_namespace, function(e) {
            bobHide(e);
            removeHash();
        });

        /**
         * Close bob-frame if a close button is clicked
         */
         $(document).on('click' + event_namespace, '.bob-close', function() {
            window.history.back();
        });

        /**
         * Close bob-frame on esc
         */
         $(document).on('keydown' + event_namespace, function(e) {
            e = e || window.event;
            if (e.keyCode === 27) {
                let bobFrameState = _.getJsonSessionStorage(g.STORAGE_FRAME_STATE);
                let bobMsgOpen = _.getJsonSessionStorage(g.STORAGE_MSG_STATE);
                if (bobFrameState.frameOpen && !bobMsgOpen.msgOpen) {
                    window.history.back();
                }
            }
        });

        /**
         * ------------------
         */

        /**
         * Call bobShow directly
         * param src is the id of the content source
         * param callerId is the id of the caller element or the element that should get focus after bob-frame closed
         */
        
        if (arguments.length > 0) {
            if (arguments[0] === 'open') {
                let src = arguments[1];
                let callerId = arguments[2];
                if (src && src.substr(0, 1) === '#' && callerId) {
                    bobShow(src, callerId);
                }
            }

            // add more argument options, if you like...
        }

        /**
         * ------------------
         */

        return this;

    };

}(jQuery));