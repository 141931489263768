import * as Globals from './globals_reviga_ch.js';
import ValidationTarget from "./ValidationTarget";

/**
 * Validates the form inputs
 * handles invalid and valid input fields
 * returns true if valid, or false if not
 */
export function validateForm() {

    let g = Globals;
    let formData = _.getFormValidationData(g.FRAME_FORM_SELECTOR);
    let results = [];

    let validationTargets = [
        formData.values.firstname,
        formData.values.lastname,
        formData.values.email,
    ];

    validationTargets.forEach(function (item) {
        let that = new ValidationTarget(item)
        that.hasFailedToValidate() ? that.handleInvalid() : that.handleValid();
        results.push(that.isValid);
    })

    return results.every(el => el === true);
}