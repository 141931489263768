import * as Globals from './globals_reviga_ch.js';

/**
 * sends an email via the /mail endpoint
 */
export function  createTicket (data) {

    const g = Globals;
    let $submitButton = $(g.FRAME_FORM_SELECTOR + ' .submit')

    $('document').spinner('show');

    $.ajax({
        url: "/zen",
        type: "POST",
        data: data,
        cache: false,
        success: function () {

            _.throwEvent(g.FRAME_SELECTOR, g.EVENT_FRAME_OPEN, {
                'src': '#s1-bob-success'
            });

        },
        error: function (err) {

            _.throwEvent(g.FRAME_FORM_SELECTOR, g.EVENT_FORM_ERROR);
            console.error(err);

        },
        complete: function () {
            $submitButton.prop('disabled', false)
            $('document').spinner('hide');
        }
    });
}