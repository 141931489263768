// eslint-disable-next-line no-unused-vars
import _ from 'lodash';
import '../styles/main.scss';

import 'jquery';

// scripts that are copied from the libs folder.
// This is done via webpack copy plugin in webpack.common
// the files are copied into the "fromLibs" folder.
import '../scripts/fromLibs/jquery.scrollTo.min';

//Bootstrap
// import 'bootstrap';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/index';
// import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/util';

// Bob scripts form Libs (../../libs/v2/diga/scripts/)
import '../scripts/fromLibs/bobmsg4';
import '../scripts/fromLibs/fadePage';
import '../scripts/fromLibs/flexslider';
import '../scripts/fromLibs/glyphicons';
import '../scripts/fromLibs/linkTap';
import '../scripts/fromLibs/md_buttons';
import '../scripts/fromLibs/menu_active';
import '../scripts/fromLibs/menu';
import '../scripts/fromLibs/scrollTo_anchors';
import '../scripts/fromLibs/scrollTop_arrow';
import '../scripts/fromLibs/spinner';
import '../scripts/fromLibs/util2';
import '../scripts/fromLibs/tracking_class_creator'
import '../scripts/fromLibs/etrackerButtonEvents'

// Project specific scripts
import '../scripts/main';
import '../scripts/formContact'
import '../scripts/bobframe7';
import '../scripts/intersectionObserver';
