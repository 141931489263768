import * as Globals from './globals_reviga_ch.js';

/*
returns the data object that satisfies requirements for the mail endpoint
while using Globals and _.getFormData function
*/
export function getData() {

    let g = Globals;
    let data = _.getFormData(g.FRAME_FORM_SELECTOR).data;

    return {
        "name": `${data.firstname} ${data.lastname}`,
        "email": data.email,
        "subject": data.subject,
        "sender": data.sender,
        "description": data.description ? data.description : "not provided",
    }
}