import * as Globals from './globals_reviga_ch';
import {formsHandleSubmit} from "./formsHandleSubmit";
// form specific imports below
import {createTicket} from "./formContactCreateTicket";
import {validateForm} from "./formContactValidateForm";
import {getData} from "./formContactGetData";

(function ($) {

    $.fn.formZen = function (options) {

        if (!_.isObject(options)) {
            console.error('Please define settings for this plugin in main.js');
            return this;
        }

        const settings = $.extend({}, options);
        const event_namespace = '.formContactZen';
        const g = Globals;
        const $form = $(g.FRAME_FORM_SELECTOR);

        // Initate the bobMsg plugin for this form
        $form.bobMsg(settings);

        $form.off(event_namespace).on('submit' + event_namespace, function (e) {
            formsHandleSubmit(e, {
                validate: validateForm,
                getData: getData,
                makeRequest: createTicket
            })
        });

        return this;

    };

}(jQuery));